import {Serie} from '@nivo/line';

import {ApiResponse, Data, MultiQueryApiResponse, QueryParams} from '../types';

const getXAxisDimensionFromQuery = (query: QueryParams): string =>
    query.timeDimensions
        ? query.timeDimensions[0].dimension
        : query.dimensions[0];

export const transformDataForLineChart = <T extends Data>(
    data: ApiResponse<T> | MultiQueryApiResponse<T>,
) => {
    let transformedData: Serie[], query, annotation;

    if ('results' in data) {
        query = data.results[0].query;
        annotation = data.results[0].annotation;
        if (!query.dimensions && query.timeDimensions) {
            query.dimensions = query.timeDimensions.map((dim) => dim.dimension);
            annotation.dimensions = annotation.timeDimensions;
        }

        const dimension = getXAxisDimensionFromQuery(data.results[0].query);
        const measure = data.results[0].query.measures[0];
        //map multiple queries to a line each
        transformedData = data.results.map((result) => {
            if (!result.query.filters) {
                throw new Error(
                    'Multi-line queries must filter each separate query on the dimension being plotted',
                );
            }
            let relevantFilter = result.query.filters.find(
                (filter) => filter.member === dimension,
            );
            if (!relevantFilter) {
                //I think we actually need a way of mapping the correct filter to the naming of each line
                relevantFilter = result.query.filters[0];
                /*
                throw new Error(
                    'Multi-line queries must filter each separate query on the dimension being plotted',
                );
                */
            }
            const id = relevantFilter.values.join(' - ');
            return {
                id: id,
                data: result.data.map((datum) => ({
                    x: datum[dimension],
                    y: Number(datum[measure]),
                })),
            };
        });
    } else {
        query = data.query;
        annotation = data.annotation;
        const measure = data.query.measures[0];
        //line chart so assume time dimension is x-axis if present (if not just use first normal dimension in list)
        //- possibly need a way to be more intentional about this
        const xDimension = getXAxisDimensionFromQuery(data.query);
        const lineDimension = data.query.dimensions.filter(
            (dim) => dim !== xDimension,
        )[0];

        const lines = data.data.reduce<(string | number | null)[]>(
            (acc, obj) => {
                if (obj[lineDimension] && !acc.includes(obj[lineDimension])) {
                    acc.push(obj[lineDimension]);
                }
                return acc;
            },
            [],
        );
        const xPoints = data.data.reduce<(string | number | null)[]>(
            (acc, obj) => {
                if (obj[xDimension] && !acc.includes(obj[xDimension])) {
                    acc.push(obj[xDimension]);
                }
                return acc;
            },
            [],
        );

        transformedData = lines.map((line) => {
            const lineData = data.data
                .filter((datum) => datum[lineDimension] === line)
                .map((datum) => ({
                    x: datum[xDimension],
                    y: Number(datum[measure]),
                }));

            const completeLineData = xPoints.map((xPoint) => {
                const point = lineData.find((datum) => datum.x === xPoint);
                return point ? point : {x: xPoint, y: null}; // or y: 0 if you prefer
            });

            return {
                id: String(line),
                data: completeLineData,
            };
        });
    }

    return {
        transformedData,
        query: query,
        annotation: annotation,
    };
};
