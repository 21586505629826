import {MultiQueryApiResponse, Data, DashboardInfoPanelData} from '../types';

export const transformDataForInfoPanel = <T extends Data>(
    data: MultiQueryApiResponse<T>,
): DashboardInfoPanelData[] => {
    const transformedData: DashboardInfoPanelData[] = data.results.map(
        (measure) => {
            let title = '';
            if (measure.query.dimensions) {
                title += measure.query.dimensions.join(' - ');
            }
            if (measure.query.filters) {
                title = title ? title + ' - ' : title;
                title += measure.query.filters
                    .map((filter) => filter.values.join(' - '))
                    .join(' - ');
            }
            const measureData = measure.data.length
                ? measure.data[0]
                : {
                      rate: 0,
                      nationalRate: 0,
                      count: 0,
                      nationalCount: 0,
                  };

            return {
                title,
                rate: Number(measureData.rate || 0),
                nationalRate: Number(measureData.nationalRate || 0),
                count: Number(measureData.count || 0),
                nationalCount: Number(measureData.nationalCount || 0),
            };
        },
    );

    return transformedData;
};
