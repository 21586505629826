import {httpGet} from 'Interfaces/httpClient';

import {ChatMessage} from './ChatPanel';

export type HistoricMessagesResponseType = {
    items: ChatMessage[];
};

export const getHistoricMessages = async (url: string) => {
    try {
        const response: HistoricMessagesResponseType = await httpGet(url);
        if (!response || !response.items) {
            console.error(
                'Invalid response in getHistoricMessages',
                url,
                response,
            );
            return [];
        }
        const sanitizedResponse = response.items
            .map(({id, type, text}) => ({
                id,
                type,
                text,
            }))
            .filter(
                (item) =>
                    typeof item.id === 'string' &&
                    (item.type === 'agent' || item.type === 'user') &&
                    typeof item.text === 'string',
            );
        return sanitizedResponse;
    } catch (e) {
        console.error('Error in getHistoricMessages', e, url);
        return [];
    }
};
