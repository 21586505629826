import {DashboardFilterPanelOption} from './DashboardFilterPanelOption';

export const DashboardFilter = ({filter, setActiveFilters, activeFilters}) => {
    return (
        <div className="dashboard-filter-panel__filter">
            <label>{filter.dimension.title}</label>
            <span className="dashboard-filter-panel__filter-options">
                {filter.values.map((value) => {
                    const active = activeFilters.includes(value);
                    return (
                        <DashboardFilterPanelOption
                            isActive={active}
                            value={value}
                            setActiveFilters={setActiveFilters}
                        />
                    );
                })}
            </span>
        </div>
    );
};
