import {httpPost} from 'Interfaces/httpClient';

type getNewAgentMessageParams = {
    exportUrl: string;
    rawHtml: string;
    json?: string;
    canvasId?: string;
    exportType?: ExportType;
};

export type ExportType = 'save' | 'pdf' | 'doc';

type DownloadItem = {
    downloadUrl: string;
};

type AgentMessageResponse = {
    headers: Record<string, string>;
    url?: string;
    items?: DownloadItem[];
    success: boolean;
};

const triggerDownload = (url: string) => {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = '';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
};

export const postHtmlExport = async ({
    exportUrl,
    rawHtml,
    json,
    canvasId,
    exportType,
}: getNewAgentMessageParams): Promise<boolean> => {
    try {
        const payload = {
            html: rawHtml,
            json: json,
            canvasId: canvasId,
            exportType: exportType,
        };

        const response = await httpPost<AgentMessageResponse>(
            exportUrl,
            payload,
        );

        if (response.success === true) {
            if (response?.items && response?.items.length > 0) {
                triggerDownload(response?.items[0].downloadUrl); // Trigger download from items array
            }
            return true;
        }
        return false;
    } catch (e) {
        console.error('Error in postHtmlExport', e);
        return false;
    }
};
