export const RightArrowIcon = () => (
    <div className="card__right-arrow" aria-label="Right arrow icon">
        <svg viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                width="8"
                height="12"
                d="M1.5 1L6.5 6L1.5 11"
                stroke="#3C3735"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </div>
);
