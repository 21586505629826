import {ResponsiveLine} from '@nivo/line';

import {ApiResponse, Data} from '../types';

import {transformDataForLineChart} from './transformDataForLineChart';

type LineChartProps = {
    data?: ApiResponse<Data>;
};

export const LineChart = ({data}: LineChartProps) => {
    if (!data) {
        return <div>Error: no data provided</div>;
    }

    const {transformedData, query, annotation} =
        transformDataForLineChart(data);
    // TODO: this logic is replicated - should extract it
    const keys = query.measures;
    const indexBy = query.dimensions[0];
    const xAxisLabel = annotation.dimensions[indexBy].title;
    const yAxisLabel = annotation.measures[keys[0]].title;
    return (
        <ResponsiveLine
            data={transformedData}
            margin={{top: 20, right: 100, bottom: 50, left: 50}}
            xScale={{type: 'point'}}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: true,
                reverse: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: xAxisLabel,
                legendOffset: 36,
                legendPosition: 'middle',
                truncateTickAt: 0,
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: yAxisLabel,
                legendOffset: -60,
                legendPosition: 'middle',
                truncateTickAt: 0,
            }}
            pointSize={10}
            pointColor={{theme: 'background'}}
            pointBorderWidth={2}
            pointBorderColor={{from: 'serieColor'}}
            pointLabel="data.yFormatted"
            pointLabelYOffset={-12}
            enableTouchCrosshair={true}
            useMesh={true}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
            // eslint-disable-next-line no-console
            onClick={(e) => console.log(e)}
        />
    );
};
