import {BarDatum} from '@nivo/bar';

import {ApiResponse, Data} from '../types';

export const transformDataForBarChart = <T extends Data>(
    data: ApiResponse<T>,
) => {
    if (!data.query.dimensions && data.query.timeDimensions) {
        data.query.dimensions = data.query.timeDimensions.map(
            (dimension) => dimension.dimension,
        );
        data.annotation.dimensions = data.annotation.timeDimensions;
    }
    const measures = data.query.measures;
    const dimensions = data.query.dimensions;

    const transformedData: BarDatum[] = data.data.map((datum) => {
        const row: BarDatum = {} as BarDatum;

        dimensions.forEach((dim) => {
            row[dim] = datum[dim] ?? ''; // If the dimension is missing, default to an empty string - this keeps the Nivo types happy
        });

        measures.forEach((measure) => {
            row[measure] = Number(datum[measure]) || 0; // Convert numeric values to Number, fallback to 0 if missing - same, keeps Nivo happy
        });

        return row;
    });

    return {
        transformedData,
        query: data.query,
        annotation: data.annotation,
    };
};
