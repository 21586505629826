import {CanvasElement} from 'BackendRenderedComponents/chatCanvas/ChatCanvas';
import {isLoggedIn} from 'Interfaces/Session';
import {subscribe} from 'Interfaces/websocket';
import Pubsub from 'Services/pubsub/Pubsub';

export interface CanvasElementMetadata {
    pushChannel: string;
}

const CHANNEL = 'agentMessage';
const EVENT_NAME = 'agentMessage:new_message';

class AgentNotification {
    agentMessageMetadata: CanvasElementMetadata;
    private isSocketInitialized = false;
    private lastMessageContent: string | null = null;

    constructor() {
        this.agentMessageMetadata = {
            pushChannel: CHANNEL,
        };
    }

    getPushChannel = (): string => {
        return CHANNEL;
    };

    getEventName = (): string => {
        return EVENT_NAME;
    };

    init = (agentMessageMetadata?: CanvasElementMetadata) => {
        Object.assign(this.agentMessageMetadata, agentMessageMetadata);
        if (isLoggedIn() && !this.isSocketInitialized) {
            this.initSocket();
        }
    };

    initSocket = () => {
        this.isSocketInitialized = true;
        subscribe(
            this.agentMessageMetadata.pushChannel,
            EVENT_NAME,
            this.handleAgentMessage,
        );
    };

    subscribe = (callback: (message: CanvasElement) => void) => {
        const subscription = Pubsub.subscribe('receivedAgentMessage', callback);
        return () => {
            Pubsub.unsubscribe('receivedAgentMessage', subscription);
        };
    };

    handleAgentMessage = (message: CanvasElement) => {
        if (message.content === this.lastMessageContent) {
            return; // Don't publish a duplicate. This seems to be a bug that occurs when a component is updated during Dev. It doesn't happen in Prod.
        }
        this.lastMessageContent = message.content || null;
        Pubsub.publish('receivedAgentMessage', message);
    };
}

let agentNotificationInstance: AgentNotification | null = null;
export const getAgentNotificationService = () => {
    if (!agentNotificationInstance) {
        agentNotificationInstance = new AgentNotification();
    }
    return agentNotificationInstance;
};
