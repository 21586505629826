import {BarDatum, ResponsiveBar} from '@nivo/bar';

import {ApiResponse, Data} from '../types';

import {createColorGetter, colorPalette} from './colorPalette';
import {transformDataForBarChart} from './transformDataForBarChart';

type BarChartProps = {
    data?: ApiResponse<Data>;
    xAxisLabel?: string;
    grouped?: boolean;
};

export const BarChart = ({
    data,
    xAxisLabel,
    grouped = false,
}: BarChartProps) => {
    if (!data) {
        return <div>Error: no data provided</div>;
    }
    const {transformedData, query, annotation} =
        transformDataForBarChart<Data>(data);

    const keys = query.measures;
    // Concatenate by the value of all of the dimensions to create a unique index for each bar
    const indexBy = (datum: BarDatum) =>
        query.dimensions.map((dim) => String(datum[dim])).join(' - ');

    // The yAxis label should always be a percentage or a count
    // However, if there is more than one measure (e.g. `Count of negative` and `Count of positive`), then we shouldn't default to the first measure's name on the Y axis because the chart represents both "positive" and "negative" counts
    // In this case, we should default to "Count" or "Percent" based on the measure title
    let yAxisLabel = 'Count';
    let yAxisIsPercentage = false;
    if (keys.length === 1) {
        yAxisLabel = annotation.measures[keys[0]].title;
    } else if (keys.length > 1) {
        // If the word "percent", "pc" or "%" is in the title, we can assume it's a percentage. Otherwise, we'll assume it's a count
        yAxisIsPercentage = keys.some((key) =>
            annotation.measures[key].title.match(/percent|pc|%/i),
        );
        yAxisLabel = yAxisIsPercentage ? '%' : 'Count';
    }

    const CustomTick = ({
        x,
        y,
        value,
        textAnchor,
    }: {
        x: number;
        y: number;
        value: string;
        textAnchor: string;
    }) => {
        const MAX_LINE_LENGTH = 12;
        const Y_OFFSET = 15;
        const DISTANCE_BETWEEN_WORDS = 14;
        const allWords = value.split(' ');
        const lines: string[] = [];
        let currentLine = '';

        allWords.forEach((word) => {
            if ((currentLine + word).length > MAX_LINE_LENGTH) {
                if (currentLine) {
                    lines.push(currentLine.trim());
                }
                currentLine = word + ' ';
            } else {
                currentLine += word + ' ';
            }
        });
        if (currentLine) {
            lines.push(currentLine.trim());
        }

        return (
            <g transform={`translate(${x}, ${y + Y_OFFSET})`}>
                {lines.map((line, i) => (
                    <text
                        key={i}
                        textAnchor={textAnchor}
                        y={i * DISTANCE_BETWEEN_WORDS}
                        style={{fontSize: '12px', fill: '#333'}}
                    >
                        {line}
                    </text>
                ))}
            </g>
        );
    };

    return (
        <ResponsiveBar
            data={transformedData}
            keys={keys}
            indexBy={indexBy}
            colors={(d) => createColorGetter(keys)({id: d.id as string})} //TODO: it should be possible to pass a custom colour from the backend in here
            colorBy="id"
            groupMode={grouped ? 'grouped' : 'stacked'}
            margin={{top: 20, right: 200, bottom: 50, left: 60}}
            padding={0.35}
            valueScale={{
                type: 'linear',
                max: yAxisIsPercentage ? 100 : 'auto',
            }}
            indexScale={{type: 'band', round: true}}
            tooltip={({id, value, indexValue}) => {
                const measureTitle = annotation.measures[id]?.title || id; // Use the measure title, falling back to the measure key if not found
                return (
                    <div style={{padding: '6px 9px', background: 'white'}}>
                        <strong>{measureTitle}:</strong> {value} <br />
                        <em>{indexValue}</em>
                    </div>
                );
            }}
            borderColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend:
                    xAxisLabel ||
                    annotation.dimensions[query.dimensions[0]]?.title || // If xAxisLabel is not provided, use the dimension title
                    '',
                legendPosition: 'middle',
                legendOffset: 32,
                truncateTickAt: 0,
                renderTick: (tick) => (
                    <CustomTick
                        x={tick.x}
                        y={tick.y}
                        value={tick.value}
                        textAnchor="middle"
                    />
                ),
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: yAxisLabel,
                legendPosition: 'middle',
                legendOffset: -40,
                truncateTickAt: 0,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
            }}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 130,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 150,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    symbolShape: 'circle',
                    data: keys.map((key, index) => ({
                        id: key,
                        label: annotation.measures[key]?.title || key,
                        color: colorPalette[index % colorPalette.length],
                    })),
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
            role="application"
            ariaLabel="Bar chart"
            barAriaLabel={(e) =>
                e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
            }
            // eslint-disable-next-line no-console
            onClick={(e) => console.log(e)}
        />
    );
};
