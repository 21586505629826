import {Dashboard} from './Dashboard/Dashboard';

import './responsiveChartingDashboard.scss';

type ResponsiveChartingDashboardProps = {
    layoutConfig: string;
    token: string;
};

export const ResponsiveChartingDashboard = ({
    layoutConfig,
    token,
}: ResponsiveChartingDashboardProps) => {
    return (
        <div className="dashboard-container">
            <Dashboard dashboardConfigJSON={layoutConfig} authToken={token} />
        </div>
    );
};
