import classNames from 'classnames';
import {useState} from 'react';

export const DashboardFilterPanelOption = ({
    value,
    setActiveFilters,
    isActive,
}) => {
    const [active, setActive] = useState(isActive);
    return (
        <div
            className={classNames('dashboard-filter-panel__filter-option', {
                'dashboard-filter-panel__filter-option__active': active,
            })}
            onClick={() => {
                setActive((prevState) => !prevState);
                if (active) {
                    setActiveFilters((prevState) =>
                        prevState.filter((filter) => filter !== value),
                    );
                } else {
                    setActiveFilters((prevState) => [...prevState, value]);
                }
            }}
        >
            {value}
        </div>
    );
};
