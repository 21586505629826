import {httpGet} from 'Interfaces/httpClient';

import {CanvasElement} from '../chatCanvas/ChatCanvas';
import {ChatMessage} from '../chatPanel/ChatPanel';

interface HistoricDataResponseType {
    items: Array<{
        chatItems?: ChatMessage[];
        canvasJson?: CanvasElement[];
    }>;
}

export type HistoricData = {
    chatHistory: ChatMessage[];
    canvasHistory: CanvasElement[];
};

export const getHistoricCanvas = async (url: string): Promise<HistoricData> => {
    try {
        const response: HistoricDataResponseType = await httpGet(url);
        if (!response || !response.items) {
            console.error('Invalid response in getHistoricData', url, response);
            return {chatHistory: [], canvasHistory: []};
        }

        const chatHistory: ChatMessage[] = [];
        const canvasHistory: CanvasElement[] = [];

        response.items.forEach((item) => {
            if (item.chatItems) {
                chatHistory.push(...item.chatItems);
            }
            if (item.canvasJson) {
                canvasHistory.push(...item.canvasJson);
            }
        });

        return {chatHistory, canvasHistory};
    } catch (e) {
        console.error('Error in getHistoricData', e, url);
        return {chatHistory: [], canvasHistory: []};
    }
};
