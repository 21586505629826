import React from 'react';

type HtmlTableProps = {
    id?: string;
    content: Content;
    className?: string;
};

type Column = {
    dataIndex: string;
    text: string;
    sortable: boolean;
};

type Row = {
    fields: Record<
        string,
        {
            value:
                | string
                | number
                | Record<string, string | number>
                | Array<Record<string, string | number>>;
        }
    >;
};

type Content = {
    columns: Column[];
    data: Row[];
};

export const CanvasHtmlTable: React.FC<HtmlTableProps> = ({
    id,
    content,
    className,
}) => {
    const {columns, data} = content;

    const renderCellContent = (
        value:
            | string
            | number
            | Record<string, string | number>
            | Array<Record<string, string | number>>
            | undefined,
    ) => {
        if (Array.isArray(value)) {
            return (
                <ul>
                    {value.map((item, index) => (
                        <li key={index}>
                            {Object.entries(item).map(([key, val]) => (
                                <div key={key}>
                                    <strong>{key}:</strong> {val}
                                </div>
                            ))}
                        </li>
                    ))}
                </ul>
            );
        }
        if (typeof value === 'object' && value !== null) {
            return (
                <ul>
                    {Object.entries(value).map(([key, val]) => (
                        <li key={key}>
                            <strong>{key}:</strong> {val}
                        </li>
                    ))}
                </ul>
            );
        }
        return value || '';
    };

    return (
        <table
            id={`hidden-export-table-${id}`}
            className={`canvas-html-table ${className || ''}`}
        >
            <thead>
                <tr>
                    {columns.map((column, index) => (
                        <th key={`header-${index}`}>{column.text}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, rowIndex) => (
                    <tr key={`row-${rowIndex}`}>
                        {columns.map((column, cellIndex) => (
                            <td key={`cell-${rowIndex}-${cellIndex}`}>
                                {renderCellContent(
                                    row.fields[column.dataIndex]?.value,
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
