import {removeAllAndAddWindow} from 'Interfaces/WindowManager';
import {isExtComponent} from 'Renderer/componentCache';
import {ExtLayoutRenderer} from 'Renderer/ExtLayoutRenderer';
import {getIsComboboxAlphaModeEnabled} from 'Renderer/getIsComboboxAlphaModeEnabled';
import {
    renderExtContent,
    renderUIContent,
    setComponentKey,
} from 'Renderer/Helper';
import {
    RendererParams,
    WindowContentItem,
    WindowRendererData,
} from 'Renderer/Renderer';

import type {ReactNode} from 'react';

const renderWindowContent = (
    data: WindowContentItem,
    rendererParams: RendererParams,
) => {
    if (typeof data.content === 'string') {
        console.warn('Window content should be an array', data);
        return [];
    }

    // Some components like CheckoutDownloadInvoiceButton rely on
    // knowing the role of their parent window, so we will pass the window's
    // role into rendererParams for easy access. Hopefully we can remove all these
    // cases when the Insight product is deleted
    const {role, formActions} = data.props;
    const childRendererParams = {...rendererParams, role};

    if (typeof formActions !== 'undefined') {
        childRendererParams.parentFormActions = formActions;
    }

    const windowChildren = data.content.map((cont, index) => {
        setComponentKey(cont, index);

        if (cont.componentName === 'Arbor.formfield.TagField') {
            if (getIsComboboxAlphaModeEnabled()) {
                cont.componentName = 'Arbor.formfield.ReactCombobox';
            }
        }

        if (isExtComponent(cont.componentName)) {
            return renderExtContent(cont, childRendererParams);
        }
        return renderUIContent(cont, childRendererParams);
    });

    const windowContainsExtChildren = data.content.find((child) =>
        isExtComponent(child.componentName),
    );

    const {title, dockedItems, header, ...otherProps} = data.props;

    return windowContainsExtChildren ? (
        <ExtLayoutRenderer
            children={windowChildren}
            componentName="Mis.container.ExtLayoutContainer"
            {...otherProps}
        />
    ) : (
        windowChildren
    );
};

/**
 * Method that takes the json response data, goes through all the window config objects (if there are any), creates a window configuration out of the data,
 * and shows the created windows.
 * Used for window rendering.
 *
 * @method
 * @public
 * @since v2.2.0
 * @param {Object} data - Data (JSON response) to be rendered into a window view.
 * @param {Object} rendererParams - Additional data to be passed to all components rendered
 *
 * @throws {Error} Error if there is no window to be shown
 * @memberof Renderer
 */
export function renderWindows(
    data: WindowRendererData,
    rendererParams: RendererParams,
) {
    if (data.content && data.content.length > 0) {
        data.content.forEach((item) => {
            let footerItems: ReactNode[] | null = null;

            const {dockedItems, title, id, responsive, sizing} = item.props;

            if (dockedItems && dockedItems.length > 0) {
                // Render the footer content with react
                footerItems = dockedItems.map((dockedItem, index) => {
                    setComponentKey(dockedItem, index);
                    return renderUIContent(dockedItem, rendererParams);
                });
            }

            const windowContent = renderWindowContent(item, rendererParams);

            removeAllAndAddWindow({
                content: windowContent,
                rendererParams,
                footerItems,
                title,
                id,
                responsive,
                sizing,
            });
        });
    } else {
        throw new Error(
            "The response doesn't have content for Windows, so we can't create any!",
        );
    }
}
