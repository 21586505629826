import classNames from 'classnames';
import {useState} from 'react';

import {Icon} from 'Components/icon';

import {DashboardFilter} from './DashboardFilter';
import {DahsboardFiltericon} from './DashboardFilterIcon';

export const DashboardFilterPanel = ({
    filters,
    onExpand,
    expanded,
    //chartsToUpdate,
}) => {
    const [activeFilters, setActiveFilters] = useState([]);

    // uncomment this when ready to start filtering
    // useEffect(() => {
    //     chartsToUpdate.forEach((chart) => {
    //         // Loop through the chart layout id's and refetch their data
    //     });
    // }, [activeFilters]);

    return (
        <div
            className={classNames('dashboard-filter-panel', {
                'dashboard-filter-panel__expanded': expanded,
            })}
        >
            <div className="dashboard-filter-panel__content">
                {expanded ? (
                    filters.map((filter, index) => (
                        <DashboardFilter
                            key={index}
                            activeFilters={activeFilters}
                            filter={filter}
                            setActiveFilters={setActiveFilters}
                        />
                    ))
                ) : (
                    <span className="dashboard-filter-panel__content_collapsed">
                        <DahsboardFiltericon />
                        {activeFilters.length > 0 &&
                            activeFilters.map((filter) => {
                                return (
                                    <div className="dashboard-filter-panel__filter-option__active">
                                        {filter}
                                    </div>
                                );
                            })}
                    </span>
                )}
            </div>
            <div
                onClick={() => onExpand(!expanded)}
                className="dashboard-filter-panel-expand-button"
            >
                <Icon iconName={expanded ? 'arrow-up' : 'arrow-down'} />
            </div>
        </div>
    );
};
