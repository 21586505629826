import {
    DefaultHeatMapDatum,
    HeatMapDatum,
    HeatMapSerie,
    ResponsiveHeatMap,
} from '@nivo/heatmap';

import {ApiResponse, Data} from '../types';

import {transformDataForLineChart} from './transformDataForLineChart';

type HeatMapProps = {
    data?: ApiResponse<Data>;
};

export const HeatMap = ({data}: HeatMapProps) => {
    if (!data) {
        return <div>Error: no data provided</div>;
    }
    const {transformedData, query, annotation} =
        transformDataForLineChart(data);

    const keys = query.measures;
    const indexBy = query.dimensions[0];
    const xAxisLabel = annotation.dimensions[indexBy].title;
    const yAxisLabel = annotation.measures[keys[0]].title;

    return (
        <ResponsiveHeatMap
            data={
                transformedData as HeatMapSerie<
                    DefaultHeatMapDatum,
                    HeatMapDatum
                >[]
            }
            margin={{top: 100, right: 180, bottom: 50, left: 100}}
            valueFormat=">-.2s"
            axisTop={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -90,
                legend: xAxisLabel,
                legendOffset: 46,
                truncateTickAt: 0,
            }}
            axisRight={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: yAxisLabel,
                legendPosition: 'middle',
                legendOffset: 70,
                truncateTickAt: 0,
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: yAxisLabel,
                legendPosition: 'middle',
                legendOffset: -72,
                truncateTickAt: 0,
            }}
            colors={{
                type: 'diverging',
                scheme: 'red_yellow_blue',
                divergeAt: 0.5,
                minValue: -100000,
                maxValue: 100000,
            }}
            emptyColor="#555555"
            legends={[
                {
                    anchor: 'bottom',
                    translateX: 0,
                    translateY: 30,
                    length: 400,
                    thickness: 8,
                    direction: 'row',
                    tickPosition: 'after',
                    tickSize: 3,
                    tickSpacing: 4,
                    tickOverlap: false,
                    tickFormat: '>-.2s',
                    title: 'Value →',
                    titleAlign: 'start',
                    titleOffset: 4,
                },
            ]}
            // eslint-disable-next-line no-console
            onClick={(e) => console.log(e)}
        />
    );
};
