import React from 'react';

import {Table} from 'Components/table';
import {ISisColumnData, DataType, IStoreType} from 'Components/table/newTypes';

type Content = {
    columns: ISisColumnData[];
    data: DataType[];
};

type CanvasTableProps = {
    id: string;
    content: Content;
};

export const CanvasTable: React.FC<CanvasTableProps> = ({id, content}) => {
    const store: IStoreType = {data: content.data};

    return (
        <Table
            columns={content.columns}
            store={store}
            idProperty={'canvas__table-' + id}
            placeholder="No data to display"
        />
    );
};
