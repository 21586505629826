import {httpGet} from 'Interfaces/httpClient';

export const fetchDashboardItemData = async (url, params) => {
    const response = await httpGet(url, params);

    // mock the response here for local development
    // const response = [
    //     {
    //         title: 'Secondary',
    //         rate: 40,
    //         nationalRate: 20,
    //         count: 230,
    //         nationalCount: 300,
    //     },
    //     {
    //         title: 'Primary',
    //         rate: 35,
    //         nationalRate: 25,
    //         count: 350,
    //         nationalCount: 250,
    //     },
    //     {
    //         title: 'Special',
    //         rate: 8,
    //         nationalRate: 18,
    //         count: 180,
    //         nationalCount: 280,
    //     },
    //     {
    //         title: 'Boys',
    //         rate: 12,
    //         nationalRate: 22,
    //         count: 120,
    //         nationalCount: 220,
    //     },
    //     {
    //         title: 'Girls',
    //         rate: 25,
    //         nationalRate: 15,
    //         count: 50,
    //         nationalCount: 150,
    //     },
    // ];

    return response;
};
