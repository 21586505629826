import classNames from 'classnames';

import {DashboardInfoPanelProps, Data} from '../types';

import {transformDataForInfoPanel} from './transformDataForInfoPanel';

export const DashboardInfoPanel = ({data}: DashboardInfoPanelProps) => {
    if (!data) {
        return <div>Error: no data provided</div>;
    }

    const transformedData = transformDataForInfoPanel<Data>(data);

    return (
        <div className="dashboard-infopanel">
            <div className="dashboard-infopanel-labels">
                <div className="dashboard-infopanel-top-label">Your Rate</div>
                <div className="dashboard-infopanel-bottom-label">
                    National Rate
                </div>
                <div className="dashboard-infopanel-top-label">Your Count</div>
                <div className="dashboard-infopanel-bottom-label">
                    National Count
                </div>
            </div>
            <div className="dashboard-infopanel-metrics">
                {transformedData.map((datum) => (
                    <div className="dashboard-infopanel-metric">
                        <div>{datum.title}</div>
                        <div className="dashboard-infopanel-metric-values-container">
                            <div
                                className={classNames(
                                    'dashboard-infopanel-metric-values',
                                    {
                                        'dashboard-infopanel-metric-values__amber':
                                            datum.rate < datum.nationalRate,
                                    },
                                )}
                            >
                                {datum.rate}
                                <div className="dashboard-infopanel-metric-seperator" />
                                {datum.nationalRate}
                            </div>
                            <div
                                className={classNames(
                                    'dashboard-infopanel-metric-values',
                                    {
                                        'dashboard-infopanel-metric-values__amber':
                                            datum.count < datum.nationalCount,
                                    },
                                )}
                            >
                                {datum.count}
                                <div className="dashboard-infopanel-metric-seperator" />
                                {datum.nationalCount}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
