import {useQuery} from '@tanstack/react-query';
import React from 'react';

import {fetchDashboardItemData} from './fetchDashboardItemData';

export const DashboardItemWithDataFetching = ({
    dataQuery,
    queryKey,
    children,
}) => {
    const {dataUrl, params} = dataQuery;
    const {data, isError, isLoading} = useQuery({
        queryKey: [queryKey],
        queryFn: () => fetchDashboardItemData(dataUrl, params),
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error fetching data</div>;
    }

    return React.cloneElement(children, {data});
};
