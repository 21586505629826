import classNames from 'classnames';
import {useRef, useState} from 'react';

import {Icon} from 'Components/icon';
import {useSimpleTooltip} from 'Components/tooltip';

import {sendMessageRating} from './sendMessageRating';

import type {ChatMessage} from './ChatPanel';

type RatingThumbsProps = {
    ratingUrl: string;
    message: ChatMessage;
    chatId: string;
};
export const RatingThumbs = ({
    ratingUrl,
    message,
    chatId,
}: RatingThumbsProps) => {
    const upRef = useRef<HTMLSpanElement>(null);
    const downRef = useRef<HTMLSpanElement>(null);
    const {tooltipEventHandlers: upEventHandlers} = useSimpleTooltip({
        tooltip: 'Good response',
        elementRef: upRef,
        preferPosition: 'bottom',
    });
    const {tooltipEventHandlers: downEventHandlers} = useSimpleTooltip({
        tooltip: 'Bad response',
        elementRef: downRef,
        preferPosition: 'bottom',
    });
    const [rating, setRating] = useState<'up' | 'down' | null>(null);

    const storeRating = (newRating: 'up' | 'down') => {
        sendMessageRating({
            ratingUrl,
            messageId: message.id,
            rating: newRating,
            chatId,
        });
    };
    return (
        <div className="chat-panel-message__rating">
            <Icon
                setRef={upRef}
                {...upEventHandlers}
                iconName="p-behaviour"
                aria-label="Rate message as good response"
                role="button"
                className={classNames('rating-thumb', {
                    'rating-thumb--enabled': rating === 'up',
                })}
                onClick={() => {
                    setRating('up');
                    storeRating('up');
                }}
            />
            <Icon
                setRef={downRef}
                {...downEventHandlers}
                iconName="n-behaviour"
                className={classNames('rating-thumb', {
                    'rating-thumb--enabled': rating === 'down',
                })}
                aria-label="Rate message as bad response"
                role="button"
                onClick={() => {
                    setRating('down');
                    storeRating('down');
                }}
            />
        </div>
    );
};
