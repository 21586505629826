import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {useState} from 'react';
import {Responsive, WidthProvider} from 'react-grid-layout';

import {MoveIcon} from '../ChartIcons';
import {BarChart} from '../Charts/BarChart';
import {HeatMap} from '../Charts/HeatMap';
import {LineChart} from '../Charts/LineChart';
import {DashboardFilterPanel} from '../DashboardFilter/DashboardFilterPanel';
import {DashboardInfoPanel} from '../DashboardInfoPanel/DashboardInfoPanel';
import {Dashboard as DashboardType, DashboardProps, GridItem} from '../types';

import {DashboardItemWithDataFetching} from './DashboardItemWithDataFetching';

import './dashboard.scss';

const ResponsiveGridLayout = WidthProvider(Responsive);

const queryClient = new QueryClient();

export const Dashboard = ({dashboardConfigJSON, authToken}: DashboardProps) => {
    const [expandedFilters, setExpandedFilters] = useState(false);
    const [editingLayout, setEditingLayout] = useState(false);

    const layout = JSON.parse(dashboardConfigJSON) as DashboardType;
    const {
        filters,
        filterableCharts,
        filterLayout,
        charts,
        dashboards,
        infoPanel,
    } = layout;

    return (
        <QueryClientProvider client={queryClient}>
            <button onClick={() => setEditingLayout(!editingLayout)}>
                {editingLayout ? 'Done' : 'Edit Layout'}
            </button>
            <ResponsiveGridLayout
                className="dashboard"
                maxHeight={'100%'}
                breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
                rowHeight={140}
                maxRows={8} //  maxRows is overwritten by the layout to fit the elements, it restricts when dragging elements to an extra row.
                // it's a direct measurement of how many 'h' values from an item can fit on top of eachother
                isResizable={false}
                draggableHandle=".draggable-handle"
                resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
                autoSize={false}
                compactType="vertical"
            >
                {infoPanel && (
                    <div
                        data-grid={infoPanel.layout}
                        key={infoPanel.layout.i}
                        className="dashboard-item"
                    >
                        {editingLayout && (
                            <div className="draggable-handle">
                                <MoveIcon />
                            </div>
                        )}
                        <DashboardItemWithDataFetching
                            dataQuery={infoPanel.query}
                            queryKey={infoPanel.layout.i}
                        >
                            <DashboardInfoPanel />
                        </DashboardItemWithDataFetching>
                    </div>
                )}
                {filterLayout && filters && (
                    <div
                        data-grid={filterLayout}
                        key={filterLayout.i}
                        style={{zIndex: expandedFilters ? '50' : '0'}}
                    >
                        <DashboardFilterPanel
                            //chartsToUpdate={filterableCharts}
                            filters={filters}
                            onExpand={setExpandedFilters}
                            expanded={expandedFilters}
                        />
                    </div>
                )}

                {filterableCharts &&
                    filterableCharts.map((item: GridItem) => {
                        const queryWithToken = {
                            ...item.query,
                            params: {...item.query.params, authToken},
                        };
                        return (
                            <div
                                className="dashboard-item"
                                key={item.layout.i}
                                data-grid={item.layout}
                            >
                                {editingLayout && (
                                    <div className="draggable-handle">
                                        <MoveIcon />
                                    </div>
                                )}

                                {item.chartType === 'bar' && (
                                    <DashboardItemWithDataFetching
                                        dataQuery={queryWithToken}
                                        queryKey={item.layout.i}
                                    >
                                        <BarChart />
                                    </DashboardItemWithDataFetching>
                                )}
                                {item.chartType === 'line' && (
                                    <DashboardItemWithDataFetching
                                        dataQuery={queryWithToken}
                                        queryKey={item.layout.i}
                                    >
                                        <LineChart />
                                    </DashboardItemWithDataFetching>
                                )}
                                {item.chartType === 'heat-map' && (
                                    <DashboardItemWithDataFetching
                                        dataQuery={queryWithToken}
                                        queryKey={item.layout.i}
                                    >
                                        <HeatMap />
                                    </DashboardItemWithDataFetching>
                                )}
                            </div>
                        );
                    })}
                {charts &&
                    charts.map((item: GridItem) => {
                        const queryWithToken = {
                            ...item.query,
                            params: {...item.query.params, authToken},
                        };
                        return (
                            <div
                                className="dashboard-item"
                                key={item.layout.i}
                                data-grid={item.layout}
                            >
                                {editingLayout && (
                                    <div className="draggable-handle">
                                        <MoveIcon />
                                    </div>
                                )}
                                {item.chartType === 'bar' && (
                                    <DashboardItemWithDataFetching
                                        dataQuery={queryWithToken}
                                        queryKey={item.layout.i}
                                    >
                                        <BarChart />
                                    </DashboardItemWithDataFetching>
                                )}
                                {item.chartType === 'line' && (
                                    <DashboardItemWithDataFetching
                                        dataQuery={queryWithToken}
                                        queryKey={item.layout.i}
                                    >
                                        <LineChart />
                                    </DashboardItemWithDataFetching>
                                )}
                                {item.chartType === 'heat-map' && (
                                    <DashboardItemWithDataFetching
                                        dataQuery={queryWithToken}
                                        queryKey={item.layout.i}
                                    >
                                        <HeatMap />
                                    </DashboardItemWithDataFetching>
                                )}
                            </div>
                        );
                    })}
                {dashboards &&
                    dashboards.map((dashboard: DashboardType, index) => {
                        return (
                            <div
                                key={index}
                                data-grid={dashboard.dashboardLayout}
                            >
                                <Dashboard
                                    dashboardConfigJSON={JSON.stringify(
                                        dashboard,
                                    )}
                                    authToken={authToken}
                                />
                            </div>
                        );
                    })}
            </ResponsiveGridLayout>
        </QueryClientProvider>
    );
};
