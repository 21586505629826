import {BarDatum} from '@nivo/bar';

export const transformRequestDataToChartData = (inputData: any) => {
    const derivedMeasures = Object.keys(inputData.annotation.measures);

    const derivedDimensions = [
        ...(inputData.annotation.dimensions
            ? Object.keys(inputData.annotation.dimensions)
            : []),
        ...(inputData.annotation.timeDimensions
            ? Object.keys(inputData.annotation.timeDimensions)
            : []),
    ];

    const transformedData = inputData.data.map((item: any) => {
        const transformedItem: BarDatum = {};
        derivedMeasures.forEach((measure) => {
            transformedItem[measure] = item[measure];
        });
        derivedDimensions.forEach((dimension) => {
            transformedItem[dimension] = item[dimension];
        });
        return transformedItem;
    });

    return {
        data: transformedData,
        annotation: inputData.annotation,
        query: {
            measures: derivedMeasures,
            dimensions: derivedDimensions,
        },
    };
};
