import classNames from 'classnames';
import React from 'react';

type MicButtonProps = {
    onClick: () => void;
    color?: 'green' | 'primary' | 'secondary';
    ariaLabel: string;
    tooltipHTML?: string;
    disabled?: boolean;
    className?: string;
};
export const MicButton: React.FC<MicButtonProps> = ({
    onClick,
    color = 'green',
    ariaLabel,
    tooltipHTML,
    disabled = false,
    className = '',
}) => {
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={classNames(
                'arbor-btn',
                `arbor-btn-${color}`,
                'arbor-btn-primary',
                {
                    'arbor-btn-primary': color === 'primary',
                    'arbor-btn-secondary': color === 'secondary',
                    'arbor-btn-disabled': disabled,
                },
                className,
            )}
            aria-label={ariaLabel}
            title={tooltipHTML}
        >
            {disabled ? (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-mic-off"
                >
                    <line x1="2" x2="22" y1="2" y2="22" />
                    <path d="M18.89 13.23A7.12 7.12 0 0 0 19 12v-2" />
                    <path d="M5 10v2a7 7 0 0 0 12 5" />
                    <path d="M15 9.34V5a3 3 0 0 0-5.68-1.33" />
                    <path d="M9 9v3a3 3 0 0 0 5.12 2.12" />
                    <line x1="12" x2="12" y1="19" y2="22" />
                </svg>
            ) : (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-mic"
                >
                    <path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z" />
                    <path d="M19 10v2a7 7 0 0 1-14 0v-2" />
                    <line x1="12" x2="12" y1="19" y2="22" />
                </svg>
            )}
        </button>
    );
};
