export const colorPalette = [
    '#AFD318',
    '#68AA23',
    '#CFE574',
    '#FDEC73',
    '#FABE78',
    '#32AABF',
    '#ADF3FF',
];
/**
 * Creates a color getter function based on the provided keys.
 * This allows the color of a bar to be determined based on its key.
 * If a key's index exceeds the colorPalette length, it defaults to the first color.
 *
 * @param   {string[]}  keys  An array of keys corresponding to the data measures
 *
 * @return  {[]}              Teh corresponding colours
 */
export const createColorGetter = (keys: string[]) => {
    return (bar: {id: string}) => {
        const keyIndex = keys.indexOf(bar.id);
        return keyIndex >= 0 && keyIndex < colorPalette.length
            ? colorPalette[keyIndex]
            : colorPalette[0];
    };
};
